import type { AssertGraphqlResponseProps } from '@lib/graphql/assertGraphqlResponse';
import { assertGraphqlResponse } from '@lib/graphql/assertGraphqlResponse';
import type { CheckIsUserErrorOnUserError } from '@lib/graphql/checkIsUserError';
import { checkIsUserError } from '@lib/graphql/checkIsUserError';
import type { AbstractGraphQLError } from '@lib/graphql/error';
import { useMemo } from 'react';

interface UseAssertGraphQlProps<T extends { __typename: string } | AbstractGraphQLError>
    extends AssertGraphqlResponseProps<T> {
    fetching: boolean;
    pause?: boolean;
    onTypeMismatch?: 'LOG_ERROR' | 'DO_NOTHING';
    onUserError?: 'DO_NOT_CHECK' | `CHECK_AND_${CheckIsUserErrorOnUserError}`; // eslint-disable-line prettier/prettier -- type join
}

export type UseAssertGraphQlPropsGeneric = UseAssertGraphQlProps<{ __typename: string } | AbstractGraphQLError>;

/**
 * Helper to handle GraphQL response union and top-level errors.
 * Parameters extend `assertGraphqlResponse`.
 * @param expectedTypenames
 * Expected typenames of the data object.
 * *IMPORTANT*: should either be defined out of the component or memoized.
 * *IMPORTANT*: the list can not include any error-like type names (which extends AbstractGraphQLError)
 * @param fetching
 * Fetching state of the query or mutation
 * @param pause
 * Pause condition of the query or mutation
 * @param onTypeMismatch
 * Action to take if the typename does not match the expected typename.
 * Logs an error by default. Excludes 'THROW_EXCEPTION' option.
 * @param onUserError
 * Action to take if the typename is one of 'ErrorNotFound' | 'ErrorForbidden' | 'ErrorUnauthorized'
 * Default: 'DO_NOT_CHECK'
 * Please note: when one of the expected type is from the list above, `onUserError` is not checked at all
 * @returns
 * The data object if the typename matches the expected typename, null otherwise.
 * Returned object type is narrowed to exclude types that extend AbstractGraphQLError.
 * @example
 * const EXPECTED_COUNT_ADS_TYPENAMES = ['CountAds'] as const;
 *
 * export const SubmitButton = () => {
 *  const isQueryPaused = apiFormValues === null;
 *  const [{ data, fetching, error, operation }] = useQuery({
 *   query: COUNT_ADS_QUERY,
 *   variables,
 *   pause: isQueryPaused,
 *  });
 *
 *  const countAds = useAssertGraphqlResponse({
 *   fetching,
 *   pause: isQueryPaused,
 *   onUserError: 'CHECK_AND_LOG_WARNING,
 *   data: data?.countAds,
 *   expectedTypenames: EXPECTED_COUNT_ADS_TYPENAMES,
 *   graphqlError: error,
 *   logErrorPrefix: '[SearchFormSubmitButton]',
 *   operation,
 *  });
 *
 *  // ...
 * }
 */
export const useAssertGraphqlResponse = <T extends { __typename: string } | AbstractGraphQLError>({
    fetching,
    pause,
    data,
    expectedTypenames,
    graphqlError,
    onTypeMismatch,
    onUserError = 'DO_NOT_CHECK',
    logErrorPrefix,
    logExtraData,
    urqlOptions,
    operation,
}: UseAssertGraphQlProps<T>): Exclude<T, AbstractGraphQLError> | null => {
    // We set actions to 'DO_NOTHING' on pause instead of returning null to mirror the behavior of useQuery
    // as the data is not null while fetching or paused if it was previously fetched
    const isFetchingOrPaused = fetching || pause;

    const assertedData = useMemo(() => {
        if (
            onUserError !== 'DO_NOT_CHECK' &&
            checkIsUserError({
                data,
                expectedTypenames,
                onUserError: isFetchingOrPaused
                    ? 'DO_NOTHING'
                    : (onUserError.split('_AND_')[1] as CheckIsUserErrorOnUserError),
                logErrorPrefix,
            })
        ) {
            return null;
        }

        return assertGraphqlResponse({
            data,
            expectedTypenames,
            graphqlError,
            onTypeMismatch: isFetchingOrPaused ? 'DO_NOTHING' : onTypeMismatch,
            logErrorPrefix,
            logExtraData,
            urqlOptions,
            operation,
        });
    }, [
        isFetchingOrPaused,
        data,
        expectedTypenames,
        graphqlError,
        onTypeMismatch,
        onUserError,
        logErrorPrefix,
        logExtraData,
        urqlOptions,
        operation,
    ]);

    return assertedData;
};
