import { IS_TEST_ENV } from '@domains/shared/consts/isTestEnv';
import { logInfo } from '@domains/shared/helpers/logger';
import type { LaquesisPageProps } from '@lib/experiments/types/laquesisPageProps';
import type { ExperimentVariant } from '@lib/experiments/types/types';
import { useTracking } from '@lib/tracking/useTracking';
import type { FC, PropsWithChildren } from 'react';
import { createContext, useContext, useMemo } from 'react';

interface ExperimentsContextValue {
    /**
     * Provide `experiments` property only when you want to check experiment value before the ExperimentsProvider initialization.
     *
     * Important: such an experiment impression is not tracked! Either fetch a value in getServerSideProps or use `isVariantEnabled`
     *  when context is initialized. `experiments` property is not used when context is initialized.
     */
    isVariantEnabled(
        experimentName: string,
        variant: ExperimentVariant,
        experiments?: LaquesisPageProps['experiments'],
    ): boolean;
    /**
     * Provide `experiments` property only when you want to check experiment value before the ExperimentsProvider initialization.
     *
     * Important: such an experiment impression is not tracked! Either fetch a value in getServerSideProps or use `getExperimentVariant`
     *  when context is initialized. `experiments` property is not used when context is initialized.
     *
     *  @returns null when experiment is undefined
     */
    getExperimentVariant: (
        experimentName: string,
        experiments?: LaquesisPageProps['experiments'],
    ) => ExperimentVariant | null;
}

const DEFAULT_EXPERIMENTS: LaquesisPageProps['experiments'] = {};

const getExperimentVariant = (
    experimentName: string,
    experiments: LaquesisPageProps['experiments'] = {},
): ExperimentVariant | null => {
    return experiments[experimentName.toUpperCase()] || null;
};

const ExperimentsContext = createContext<ExperimentsContextValue>({
    getExperimentVariant: (
        experimentName: string,
        experiments: LaquesisPageProps['experiments'],
    ): ExperimentVariant | null => {
        if (!IS_TEST_ENV.value && !experiments) {
            // Mute log in unit tests or for specific cases when experiment variant needs to be known before Experiments provider is initialized
            logInfo(`[ExperimentsProviderContext] used before initialization for "${experimentName}" experiment`);
        }

        return getExperimentVariant(experimentName, experiments || DEFAULT_EXPERIMENTS);
    },
    isVariantEnabled: (
        experimentName: string,
        variant: ExperimentVariant,
        experiments: LaquesisPageProps['experiments'],
    ) => {
        if (!IS_TEST_ENV.value && !experiments) {
            // Mute log in unit tests or for specific cases when experiment variant needs to be known before Experiments provider is initialized
            logInfo(`[ExperimentsProviderContext] used before initialization for "${experimentName}" experiment`);
        }

        return variant === getExperimentVariant(experimentName, experiments || DEFAULT_EXPERIMENTS);
    },
});

export const useExperiments = (): ExperimentsContextValue => useContext(ExperimentsContext);

// Make `laquesisResult` partial to simplify test mocking when provider is used directly
type ExperimentsProviderProps = Omit<LaquesisPageProps, 'laquesisResult'> & Partial<LaquesisPageProps>;

export const ExperimentsProvider: FC<PropsWithChildren<ExperimentsProviderProps>> = ({
    children,
    experiments = DEFAULT_EXPERIMENTS,
}) => {
    const { trackExperiment } = useTracking();

    const value = useMemo<ExperimentsContextValue>(
        () => ({
            isVariantEnabled: (experimentName, variant): ReturnType<ExperimentsContextValue['isVariantEnabled']> => {
                const experimentValue = getExperimentVariant(experimentName, experiments);

                if (experimentValue) {
                    // We send experiment impression with fetched value only when it is available
                    trackExperiment({ experimentName, variant: experimentValue as ExperimentVariant });
                }

                return experimentValue === variant;
            },
            getExperimentVariant: (experimentName): ReturnType<ExperimentsContextValue['getExperimentVariant']> => {
                const experimentValue = getExperimentVariant(experimentName, experiments);

                if (experimentValue) {
                    // We send experiment impression with fetched value only when it is available
                    trackExperiment({ experimentName, variant: experimentValue as ExperimentVariant });
                }

                return experimentValue;
            },
        }),
        [experiments, trackExperiment],
    );

    return <ExperimentsContext.Provider value={value}>{children}</ExperimentsContext.Provider>;
};
