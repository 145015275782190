import { logError, logWarn } from '@domains/shared/helpers/logger';
import { I18NContext } from '@lib/i18n/I18nContext';
import type { Translations } from '@lib/i18n/types/translations';
import { useCallback, useContext } from 'react';

export type Translator = (key: string, params?: Record<string, string>) => string;

export const useTranslations = (): [Translator] => {
    const translations: Translations = useContext(I18NContext);

    const t: Translator = useCallback(
        (key, params) => {
            const translationValue = translations[key];

            if (!translationValue) {
                // Note: Please do not change the message or error level - it is used to filter out those events in Sentry
                logWarn('Missing translation', { key }, { hideOnEnvs: ['test', 'production.server'] });

                return key;
            }

            if (typeof translationValue !== 'string') {
                /**
                 * // If translation is still object and not a final string, eg.
                 *  const nestedTranslation = {
                 *      a: 'some string',
                 *      b: {
                 *          x: 'some other string',
                 *      }
                 *  };
                 *  get(nestedTranslations, 'nestedTranslations.b');
                 */
                logError(
                    `Translation key "${key}" is an object with plural forms. Select one specific form value`,
                    {},
                    { hideOnEnvs: ['test', 'production.server'] },
                );

                return key;
            }

            if (!params || Object.keys(params).length === 0) {
                return translationValue;
            }

            return translationValue.replace(/%\w+%/g, (placeholder) => {
                const propKey = placeholder.split('%').join('');

                return Object.prototype.hasOwnProperty.call(params, propKey) ? params[propKey] : propKey;
            });
        },
        [translations],
    );

    return [t];
};
