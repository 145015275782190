import { ADMIN_USER_STATUS } from '@type/user/adminUserStatus';
import type { UserStatus } from '@type/user/userStatus';
import { USER_STATUS } from '@type/user/userStatus';
import type { UserType } from '@type/user/userType';

interface User {
    id: number;
    isUnconfirmed: boolean;
    userType: UserType;
}

const DEFAULT_NON_LOGGED_USER_DATA = {
    business_status: null,
    user_id: null,
    user_status: USER_STATUS.unlogged,
} as const;

const getLoggedInUserLoginType = (user: Pick<User, 'isUnconfirmed'>): UserStatus => {
    if (typeof document === 'undefined') {
        throw new TypeError('[TrackingContext] getLoggedInUserLoginType cannot be used in SSR');
    }

    if (user.isUnconfirmed) {
        return USER_STATUS.loggedMail;
    }

    if (document.cookie.includes(`login_type=${ADMIN_USER_STATUS.adminPanel}`)) {
        return USER_STATUS.loggedInAuto;
    }

    return USER_STATUS.loggedIn;
};

export const getTrackingUserData = (
    user: Pick<User, 'id' | 'isUnconfirmed' | 'userType'> | null | undefined,
): {
    business_status: UserType | null;
    user_id: number | null;
    user_status: UserStatus | null;
} => {
    if (!user) {
        return DEFAULT_NON_LOGGED_USER_DATA;
    }

    return {
        business_status: user.userType,
        user_id: user.id,
        user_status: getLoggedInUserLoginType(user),
    };
};
