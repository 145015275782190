import type { AbstractGraphQLError } from '@lib/graphql/error';
import type { GraphQLError } from 'graphql';
import type { CombinedError } from 'urql';

const checkIsForbiddenError = (item: GraphQLError): boolean => item?.message === 'Forbidden Access';

interface ForbiddenUserError extends AbstractGraphQLError {
    __typename: 'ErrorForbidden';
}

// Keeps as constant to avoid object reference check fail, e.g. in useEffect
const ERROR_FORBIDDEN = { __typename: 'ErrorForbidden', message: '' } as const;

/**
 * This is a helper function to be able to use `useAssertGraphqlResponse` in reference when user is logged before
 * the resolver is fully migrated to new error handling. Currently, instead of typename, we receive in response an error.
 */
export const normalizeForbiddenAccessQuery = <T>(data: T, error: CombinedError | undefined): T | ForbiddenUserError => {
    if (error?.graphQLErrors?.some?.(checkIsForbiddenError)) {
        return ERROR_FORBIDDEN;
    }

    return data;
};
