import type { FC, JSX } from 'react';
import type { ToastOptions } from 'react-hot-toast';
import { toast as nativeToast } from 'react-hot-toast';

import {
    ErrorIcon,
    ErrorWrapper,
    InfoIcon,
    InfoWrapper,
    LoadingIcon,
    LoadingWrapper,
    SuccessIcon,
    SuccessWrapper,
    WarningIcon,
    WarningWrapper,
} from './Toast.theme';
import { ToastBody } from './ToastBody';

const TOAST_DURATION: Record<string, number> = {
    success: 3000,
    error: 4000,
    loading: 3000,
    warning: 4000,
    info: 3000,
};

const createToast =
    (Wrapper: FC, Icon: FC, duration: number) =>
    (
        label: string | JSX.Element,
        {
            dataCy = 'toast',
            shouldHideCloseButton = false,
            ...options
        }: ToastOptions & { dataCy?: string; shouldHideCloseButton?: boolean } = {},
    ): string =>
        nativeToast(
            (toast) => (
                <ToastBody
                    Wrapper={Wrapper}
                    Icon={Icon}
                    dataCy={dataCy}
                    label={label}
                    shouldHideCloseButton={shouldHideCloseButton}
                    onClick={(): void => nativeToast.dismiss(toast.id)}
                />
            ),
            {
                duration,
                ...options,
            },
        );

// eslint-disable-next-line @typescript-eslint/naming-convention -- avoid unnecessary refactoring
export const toast = {
    success: createToast(SuccessWrapper, SuccessIcon, TOAST_DURATION.success),
    error: createToast(ErrorWrapper, ErrorIcon, TOAST_DURATION.error),
    warning: createToast(WarningWrapper, WarningIcon, TOAST_DURATION.warning),
    loading: createToast(LoadingWrapper, LoadingIcon, TOAST_DURATION.loading),
    info: createToast(InfoWrapper, InfoIcon, TOAST_DURATION.info),
    dismiss: nativeToast.dismiss,
    remove: nativeToast.remove,
} as const;
