import type { FC, PropsWithChildren } from 'react';
import { createContext, useCallback, useMemo, useState } from 'react';

interface ModalConfig {
    modalRef: Element | null;
    setModalRef(node: HTMLDivElement): void;
}

export const ModalContext = createContext<ModalConfig>({ modalRef: null, setModalRef: () => null });

export const ModalContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [modalRef, setRef] = useState<Element | null>(null);

    const setModalRef = useCallback((node: Element) => {
        if (node) {
            setRef(node);
        }
    }, []);

    const value = useMemo(
        () => ({
            modalRef,
            setModalRef,
        }),
        [modalRef, setModalRef],
    );

    return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};
