import { IS_TEST_ENV } from '@domains/shared/consts/isTestEnv';
import { checkIsDesktop } from '@domains/shared/helpers/checkIsDesktop';
import { logWarn } from '@domains/shared/helpers/logger';
import type { LazyUserContextProps } from '@lib/pages/contexts/LazyUserContext/types';
import type { MutableRefObject } from 'react';

import type { DefaultTrackingData, TrackingEvent } from '../types';
import { getTrackingUserData } from './getTrackingUserData';

const DEFAULT_USER_DATA_AWAIT_ENTRY = { shouldAddUserData: true } as const;

export const addEventToDataLayer = (
    additionalDataRef: MutableRefObject<DefaultTrackingData>,
    userRef: MutableRefObject<Readonly<LazyUserContextProps>>,
    event: TrackingEvent,
): void => {
    if (typeof window === 'undefined') {
        logWarn('[TrackingContext] trackEvent/trackPageView was called during SSR!', { event });

        return;
    }

    // Note:
    // - when run in tests, do not add cleanup event
    // - when it's page view, cleanup the data layer (except when pv is the first event)

    const isPageView = event.event_type === 'pv';
    const isStorable = window.reTrackingStorageToUse === 'dataLayer' || window.reTrackingContextStorage.length > 0;

    if (!IS_TEST_ENV.value && isPageView && isStorable) {
        pushToDataLayer({
            cleanup() {
                /* cleanup dataLayer, e.g. route change */
            },
        });
    }

    const { isAwaitingUserData, user } = userRef.current;
    const userSpecificData = isAwaitingUserData ? DEFAULT_USER_DATA_AWAIT_ENTRY : getTrackingUserData(user);
    const platformType = checkIsDesktop() ? 'desktop' : 'mobile-html5';

    pushToDataLayer({
        ...userSpecificData,
        platformType,
        ...additionalDataRef.current,
        ...event,
    });
};

const pushToDataLayer = (event: TrackingEvent): void => {
    const shouldUseDataLayer = window.reTrackingStorageToUse === 'dataLayer';
    const isTestEnv = IS_TEST_ENV.value;

    if (isTestEnv) {
        // Note: When we're in test env we want to always push to dataLayer without any delay.
        window.dataLayer.push(event);

        return;
    }

    if (shouldUseDataLayer) {
        // Note: Delay is used here so it's not blocking the main thread during interaction.
        // `setTimeout` is used intentionally. `queueMicrotask` may look correct, but flame graph looks different.
        setTimeout(() => window.dataLayer.push(event), 0);

        return;
    }

    window.reTrackingContextStorage.push(event);
};
