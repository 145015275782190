import { logError } from '@domains/shared/helpers/logger';
import type { ExperimentEvent } from '@lib/tracking/types';

export const sendExperimentTrackingToLaquesis = (value: ExperimentEvent, shouldCheckIfReported = true): void => {
    if ('newResult' in value) {
        // server experiments impressions tracking executed in `getServerSideProps`
        // Push only non-empty values && if they are different from the latest reported
        // Reason: https://docs.data.olx.org/components/sdk/laquesis/cookie-watcher.html#how-to-add-it
        if (
            window.laquesisResults.length === 0 ||
            window.laquesisResults[window.laquesisResults.length - 1]?.newResult !== value.newResult
        ) {
            window.laquesisResults.push(value);
        }

        return;
    }
    if (!window.rePageExperimentsImpressions) {
        logError('[TrackingContext] Experiment tracking error - window.rePageExperimentsImpressions is not ready');

        return;
    }
    const { experimentName, variant } = value;
    const experimentDebouncedName = `${experimentName}@${variant}`; // Experiment is stored with variant as it may change in the SPA session
    if (shouldCheckIfReported && window.rePageExperimentsImpressions.has(experimentDebouncedName)) {
        // That impression was already send on this page

        return;
    }
    if (typeof window.getLaquesisVariant !== 'function') {
        // when it is not yet available, update the laquesisResults
        let lastResult: (typeof window.laquesisResults)[number] = { newResult: '' };
        if (window.laquesisResults.length > 0 && !window.laquesisResults[window.laquesisResults.length - 1].processed) {
            // The last event hasn't been processed yet, update it
            lastResult = window.laquesisResults.pop() as (typeof window.laquesisResults)[number];
        }
        const lowerCaseExperimentName = experimentName.toLowerCase();
        if (!lastResult.newResult.includes(lowerCaseExperimentName)) {
            // Do not add an experiment if it was already added in withBaseSsrHandler
            if (lastResult.newResult) {
                lastResult.newResult += '#';
            }
            lastResult.newResult += `${experimentName.toLowerCase()}@${variant}|t`;
        }

        window.laquesisResults.push(lastResult);
        window.rePageExperimentsImpressions.add(experimentDebouncedName);

        return;
    }
    // client side experiments - we call laquesis function responsible for tracking client side experiments
    try {
        window.getLaquesisVariant(experimentName);
    } catch (error) {
        logError('[TrackingContext] Experiment tracking error', { error });
    }
    window.rePageExperimentsImpressions.add(experimentDebouncedName);
};
