import { USER_DATA_QUERY_CONTEXT } from '@domains/shared/consts/userDataQueryContext';
import { useAssertGraphqlResponse } from '@lib/graphql/hooks/useAssertGraphqlResponse';
import { normalizeForbiddenAccessQuery } from '@lib/graphql/normalizeForbiddenAccessQuery';
import type { FC, PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { useQuery } from 'urql';

import { GET_USER_QUERY } from './graphql/queries/UserQuery';
import { useLogout } from './hooks/useLogout';
import { LazyUserContext } from './LazyUserContext';

const EXPECTED_USER_TYPENAME = ['User'] as const;

export const LazyUserContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [{ data, error: graphqlError, fetching, operation }] = useQuery({
        query: GET_USER_QUERY,
        context: USER_DATA_QUERY_CONTEXT,
    });
    const user = useAssertGraphqlResponse({
        data: normalizeForbiddenAccessQuery(data?.user, graphqlError),
        expectedTypenames: EXPECTED_USER_TYPENAME,
        fetching,
        logErrorPrefix: '[LazyUserContextProvider]',
        graphqlError,
        onTypeMismatch: 'LOG_ERROR', // We have to do nothing as instead of typename, we receive here an error 'Forbidden Access'
        onUserError: 'CHECK_AND_DO_NOTHING',
        operation,
    });
    const { logout } = useLogout(user);

    // Handle network error and possible GQL Internal Errors
    const hasErrorOccurred =
        !fetching &&
        (!!graphqlError?.networkError ||
            (!user &&
                !!data?.user &&
                data?.user?.__typename !== EXPECTED_USER_TYPENAME[0] &&
                data?.user?.__typename !== 'ErrorForbidden'));
    const value = useMemo(
        () =>
            fetching
                ? {
                      hasErrorOccurred: false,
                      isAwaitingUserData: true,
                      isUserLoggedIn: undefined,
                      user: undefined,
                  }
                : {
                      hasErrorOccurred,
                      isAwaitingUserData: false,
                      isUserLoggedIn: !!user?.id,
                      user: user || null,
                      logout,
                  },
        [fetching, hasErrorOccurred, user, logout],
    );

    return <LazyUserContext.Provider value={value}>{children}</LazyUserContext.Provider>;
};
