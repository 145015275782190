import { toast } from '@domains/shared/components/Toast/toast';
import { handleWebApiError } from '@domains/shared/helpers/handleWebApiError';
import { logWarn } from '@domains/shared/helpers/logger';
import { removeStorageItem } from '@domains/shared/helpers/storage';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { assertGraphqlResponse } from '@lib/graphql/assertGraphqlResponse';
import { USER_INFO_QUERY_CONTEXT } from '@lib/pages/contexts/LazyUserContext/const/userInfoQueryContext';
import { LOGOUT_MUTATION } from '@lib/pages/contexts/LazyUserContext/graphql/mutations/LogoutMutation';
import { getHciamLogoutUrl } from '@lib/pages/contexts/LazyUserContext/helpers/getHciamLogoutUrl';
import type { LazyUser } from '@type/user/lazyUser';
import { useCallback, useEffect, useRef } from 'react';
import { useClient } from 'urql';

const LOGOUT_MESSAGE = 'LOGOUT';

interface ReturnType {
    logout: (redirectPath?: string) => Promise<void>;
}

export const useLogout = (user: LazyUser | null = null): ReturnType => {
    const [t] = useTranslations();
    const urqlClient = useClient();
    const logoutChannelRef = useRef<BroadcastChannel | null>(null);

    useEffect(() => {
        if (!('BroadcastChannel' in window)) {
            return;
        }

        try {
            logoutChannelRef.current = new BroadcastChannel('LOGOUT_CHANNEL');
        } catch (error) {
            handleWebApiError(error, '[useLogout]');

            return;
        }

        logoutChannelRef.current.addEventListener('message', ({ data }) => {
            if (data === LOGOUT_MESSAGE) {
                window.location.reload();
            }
        });

        return (): void => {
            logoutChannelRef.current?.close();
        };
    }, []);

    const callLogoutMutation = useCallback(async (): Promise<{
        success: boolean;
        redirectToHciam: boolean;
    }> => {
        const { data, error, operation } = await urqlClient
            .mutation(LOGOUT_MUTATION, {}, USER_INFO_QUERY_CONTEXT)
            .toPromise();

        const logoutSuccess = assertGraphqlResponse({
            data: data?.logout,
            graphqlError: error,
            expectedTypenames: ['LogoutSuccess'],
            logErrorPrefix: '[useLogout]',
            operation,
        });

        if (!logoutSuccess) {
            toast.error(t('frontend.global.toast.error'));

            return {
                success: false,
                redirectToHciam: false,
            };
        }

        removeStorageItem('searchLocationID', {
            storageType: 'sessionStorage',
        });
        logoutChannelRef.current?.postMessage(LOGOUT_MESSAGE);

        return {
            success: true,
            redirectToHciam: logoutSuccess.redirectToHciam,
        };
    }, [t, urqlClient]);

    const logout = useCallback(
        async (redirectPath = '/') => {
            if (!user) {
                logWarn('[useLogout] User data does not exist, can not perform the logout action!');

                return;
            }

            const { success, redirectToHciam } = await callLogoutMutation();

            if (!success) {
                return;
            }

            const logoutRedirect = redirectToHciam ? getHciamLogoutUrl(redirectPath) : redirectPath;

            window.location.assign(logoutRedirect);
        },
        [user, callLogoutMutation],
    );

    return { logout };
};
