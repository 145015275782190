import type { ErrorInternal } from '@lib/graphql/error';
import { gql } from '@urql/core';

interface LogoutSuccess {
    __typename: 'LogoutSuccess';
    redirectToHciam: boolean;
}

type LogoutResult = LogoutSuccess | ErrorInternal;

export const LOGOUT_MUTATION = gql<{ logout: LogoutResult }>`
    mutation Logout {
        logout {
            __typename
            ... on LogoutSuccess {
                redirectToHciam
            }
        }
    }
`;
