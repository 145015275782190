import { logError, logWarn } from '@domains/shared/helpers/logger';
import type { GraphQLErrorTypename } from '@lib/graphql/error';

const DEFAULT_USER_ERROR_TYPENAMES: GraphQLErrorTypename[] = ['ErrorNotFound', 'ErrorForbidden', 'ErrorUnauthorized'];

export type CheckIsUserErrorOnUserError = 'DO_NOTHING' | 'LOG_ERROR' | 'LOG_WARNING';

interface Props<T extends { __typename: string }> {
    data: T | undefined;
    expectedTypenames: T['__typename'][] | readonly T['__typename'][];
    additionalUserErrorTypenames?: T['__typename'][] | readonly T['__typename'][];
    onUserError?: CheckIsUserErrorOnUserError;
    logErrorPrefix?: string;
}

/**
 * Helper to check if a GraphQL response is a user error.
 * @param data
 * Data object returned from a GraphQL query
 * @param expectedTypenames
 * Expected typenames of the data object
 * @param additionalUserErrorTypenames
 * Additional typenames to consider as user errors
 * @param onUserError
 * Action to take if the typename is a user error
 * No action is taken by default
 * @param logErrorPrefix
 * Prefix to use when logging errors
 * @returns
 * True if the typename of the data object is a user error (and is not in expected typenames), false otherwise
 * @example
 * const { data } = await executeSsrQuery({
 *  query: GET_LOCATIONS_DATA_QUERY,
 *  variables: { ids },
 * });
 *
 * const isUserError = checkIsUserError({ data: data?.getLocationsData, expectedTypenames: ['FoundLocationsData'] });
 * if (isUserError) {
 *  // Handle user error, e.g. return 404
 * }
 */
export const checkIsUserError = <T extends { __typename: string }>({
    data,
    expectedTypenames,
    additionalUserErrorTypenames = [],
    onUserError = 'DO_NOTHING',
    logErrorPrefix,
}: Props<T>): boolean => {
    const shouldSkipUserErrorCheck = !data || expectedTypenames.includes(data.__typename);

    if (shouldSkipUserErrorCheck) {
        return false;
    }

    const userErrorTypenames = [...DEFAULT_USER_ERROR_TYPENAMES, ...additionalUserErrorTypenames];
    const isUserError = userErrorTypenames.includes(data.__typename);

    if (isUserError && onUserError !== 'DO_NOTHING') {
        const logFunction = onUserError === 'LOG_WARNING' ? logWarn : logError;
        const prefix = logErrorPrefix ?? '[checkIsUserError]';
        logFunction(`${prefix} User Error - Expected typenames: ${expectedTypenames}, got: ${data.__typename}`);
    }

    return isUserError;
};
