import { logError, logWarn } from '@domains/shared/helpers/logger';

export const handleWebApiError = (error: unknown, errorPrefix: string): void => {
    if (!error) {
        return;
    }

    if (error instanceof DOMException) {
        if (error.name === 'NotSupportedError') {
            logWarn(`${errorPrefix} feature not supported in user configuration`, { error });

            return;
        } else if (error.name === 'SecurityError') {
            logWarn(`${errorPrefix} not accessible because of the browser settings`, { error });

            return;
        }
    }

    logError(`${errorPrefix} not accessible`, { error });
};
