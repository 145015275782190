import { ExperimentsProvider } from '@lib/experiments/client/ExperimentsProvider';
import type { LaquesisPageProps } from '@lib/experiments/types/laquesisPageProps';
import { ModalContext, ModalContextProvider } from '@lib/modalContext';
import { LazyUserContextProvider } from '@lib/pages/contexts/LazyUserContext/LazyUserContextProvider';
import { TrackingContextProvider } from '@lib/tracking/TrackingContextProvider';
import type { FC, JSX, ReactNode } from 'react';

interface PageProvidersProps extends LaquesisPageProps {
    children: ReactNode;
    defaultTrackingData?: Record<string, unknown>;
    trackPageName: string;
}

/**
 * This Provider is a wrapper over all minimally required Providers per page.
 *
 * Please avoid extending it, it should contain the total minimum!
 */
export const PageProviders: FC<PageProvidersProps> = ({
    children,
    defaultTrackingData,
    experiments,
    laquesisResult,
    trackPageName,
}) => {
    return (
        <LazyUserContextProvider>
            <TrackingContextProvider
                defaultTrackingData={defaultTrackingData}
                pageViewEventName={trackPageName}
                laquesisResult={laquesisResult}
                /* Please note:
                    We pass here the experiments ref of the page props. The experiments object is created once in getServerSideProps
                    and it is re-created only on the page view (when new page props are received).
                    Its ref is used later to compare if the page has changed or not.
                 */
                pagePropsObjectRef={experiments}
            >
                <ExperimentsProvider experiments={experiments}>
                    <ModalContextProvider>
                        {children}
                        <ModalContext.Consumer>
                            {({ setModalRef }): JSX.Element => <div ref={setModalRef} />}
                        </ModalContext.Consumer>
                    </ModalContextProvider>
                </ExperimentsProvider>
            </TrackingContextProvider>
        </LazyUserContextProvider>
    );
};
