import type { GenericGraphQLError } from '@lib/graphql/error';
import type { LazyUser } from '@type/user/lazyUser';
import { gql } from '@urql/core';

/**
 * Please note, either it looks like, but this query is not yet normalized in GQL error handling
 */
interface UserResponse {
    user: GenericGraphQLError | LazyUser;
}

export const GET_USER_QUERY = gql<UserResponse, undefined>`
    query GetUser {
        user {
            __typename
            id
            isSubAccount
            isSuspended
            isMonetizationMigrated
            isUnconfirmed
            email
            name
            userType
            isUnconfirmedBusinessUser
        }
    }
`;
