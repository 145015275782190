import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import styled from '@emotion/styled';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { useTheme } from '@nexus/lib-react/dist/theme/emotionUtils';
import type { JSX } from 'react';

export const BaseWrapper = styled.span`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 300px;
    min-height: 56px;
    padding: 16px;
    border: 1px solid;
    border-radius: 4px;

    @media (min-width: ${BREAKPOINT.sm}) {
        min-width: 380px;
    }
`;

export const CloseButton = styled.button`
    margin: 0 0 0 auto;
    padding: 0;
    border: 0;
    background: none;
    cursor: pointer;

    &:active,
    &:focus,
    &:hover {
        opacity: 0.8;
    }
`;

export const SuccessWrapper = styled(BaseWrapper)`
    border-color: ${({ theme }): string => theme.deprecated.domains.shared.toast.success.borderColor};
    background-color: ${({ theme }): string => theme.deprecated.domains.shared.toast.success.backgroundColor};
`;

export const ErrorWrapper = styled(BaseWrapper)`
    border-color: ${({ theme }): string => theme.deprecated.domains.shared.toast.error.borderColor};
    background-color: ${({ theme }): string => theme.deprecated.domains.shared.toast.error.backgroundColor};
`;

export const LoadingWrapper = styled(BaseWrapper)`
    border-color: ${({ theme }): string => theme.deprecated.domains.shared.toast.loading.borderColor};
    background-color: ${({ theme }): string => theme.deprecated.domains.shared.toast.loading.backgroundColor};
`;

export const WarningWrapper = styled(BaseWrapper)`
    border-color: ${({ theme }): string => theme.deprecated.domains.shared.toast.warning.borderColor};
    background-color: ${({ theme }): string => theme.deprecated.domains.shared.toast.warning.backgroundColor};
`;

export const InfoWrapper = styled(BaseWrapper)`
    border-color: ${({ theme }): string => theme.deprecated.domains.shared.toast.info.borderColor};
    background-color: ${({ theme }): string => theme.deprecated.domains.shared.toast.info.backgroundColor};
`;

export const Label = styled.span`
    margin: 0 8px 0 16px;
    color: ${({ theme }): string => theme.deprecated.domains.shared.toast.label.color};
    font-size: 14px;
    font-weight: 600;
`;

export const SuccessIcon = (): JSX.Element => {
    const theme = useTheme();

    return <Icon icon={faCheckCircle} size="2x" color={theme.deprecated.domains.shared.toast.success.icon.color} />;
};

export const ErrorIcon = (): JSX.Element => {
    const theme = useTheme();

    return <Icon icon={faExclamationCircle} size="2x" color={theme.deprecated.domains.shared.toast.error.icon.color} />;
};

export const WarningIcon = (): JSX.Element => {
    const theme = useTheme();

    return (
        <Icon icon={faExclamationTriangle} size="2x" color={theme.deprecated.domains.shared.toast.warning.icon.color} />
    );
};

export const LoadingIcon = (): JSX.Element => {
    const theme = useTheme();

    return (
        <Icon icon={faCircleNotch} size="2x" color={theme.deprecated.domains.shared.toast.loading.icon.color} spin />
    );
};

export const InfoIcon = (): JSX.Element => {
    const theme = useTheme();

    return <Icon icon={faInfoCircle} size="2x" color={theme.deprecated.domains.shared.toast.info.icon.color} />;
};

export const CloseIcon = (): JSX.Element => {
    const theme = useTheme();

    return <Icon icon={faTimes} size="lg" color={theme.deprecated.domains.shared.toast.closeIcon.color} />;
};
