import { logError, logWarn } from '@domains/shared/helpers/logger';
import { NETWORK_ERRORS_TO_IGNORE } from '@lib/graphql/consts/networkErrorsToIgnore';
import type { AbstractGraphQLError, GenericGraphQLErrorResponse } from '@lib/graphql/error';
import type { ExecutableDefinitionNode } from 'graphql';
import type { CombinedError, Operation, OperationContext } from 'urql';

type ErrorTypenames = GenericGraphQLErrorResponse['__typename'];

export interface AssertGraphqlResponseProps<T extends { __typename: string } | AbstractGraphQLError> {
    data: T | undefined;
    expectedTypenames: Exclude<T['__typename'], ErrorTypenames>[] | readonly Exclude<T['__typename'], ErrorTypenames>[];
    graphqlError: CombinedError | undefined;
    onTypeMismatch?: 'DO_NOTHING' | 'THROW_EXCEPTION' | 'LOG_ERROR';
    logErrorPrefix?: string;
    logExtraData?: Record<string, unknown>;
    urqlOptions?: Partial<OperationContext>;
    operation?: Operation;
}

interface PropsShouldThrowOnTypeMismatch<T extends { __typename: string } | AbstractGraphQLError>
    extends AssertGraphqlResponseProps<T> {
    onTypeMismatch: 'THROW_EXCEPTION';
}
interface PropsShouldNotThrowOnTypeMismatch<T extends { __typename: string } | AbstractGraphQLError>
    extends AssertGraphqlResponseProps<T> {
    onTypeMismatch?: 'DO_NOTHING' | 'LOG_ERROR';
}

const getLogFetchOptions = (fetchOptions?: OperationContext['fetchOptions']): RequestInit | undefined => {
    if (typeof fetchOptions === 'function') {
        return fetchOptions();
    }

    return fetchOptions;
};

/**
 * Helper to handle GraphQL response union and top-level errors.
 * If you need to handle alternate return types, use assertGraphqlResponseUnion instead.
 * @param data
 * Data object returned from a GraphQL query
 * @param expectedTypenames
 * Expected typenames of the data object
 * Important: the list can not include any error-like type names (which extends AbstractGraphQLError)
 * @param graphqlError
 * GraphQL error object extracted from query or mutation result
 * @param onTypeMismatch
 * Action to take if the typename does not match the expected typename.
 * Logs an error by default.
 * Return type is non-nullable if set to 'THROW_EXCEPTION'.
 * @param logErrorPrefix
 * Prefix to use when logging errors
 * @param logExtraData
 * Extra data to log with the error
 * @param urqlOptions
 * URQL options object
 * @param operation
 * URQL operation object extracted from query or mutation result.
 * Allows to identify the exact query or mutation that caused the error in the logs.
 * @returns
 * The data object if the typename matches the expected typename, null otherwise.
 * Returned object type is narrowed to exclude types that extend AbstractGraphQLError.
 * @example
 * const { data, error, basicUrqlOptions, operation } = await executeSsrQuery({
 *  query: GET_SELLER_PAGE_DATA_QUERY,
 *  variables: variables,
 * });
 *
 * const ownerData = assertGraphqlResponse({
 *  data: data?.owner,
 *  graphqlError: error,
 *  expectedTypenames: ['AgencyOwner', 'DeveloperOwner'],
 *  onTypeMismatch: 'THROW_EXCEPTION',
 *  logErrorPrefix: '[SellerPageServer]',
 *  urqlOptions: basicUrqlOptions,
 *  operation,
 * });
 */
export function assertGraphqlResponse<T extends { __typename: string } | AbstractGraphQLError>(
    props: PropsShouldNotThrowOnTypeMismatch<T>,
): Exclude<T, AbstractGraphQLError> | null;
export function assertGraphqlResponse<T extends { __typename: string } | AbstractGraphQLError>(
    props: PropsShouldThrowOnTypeMismatch<T>,
): Exclude<T, AbstractGraphQLError>;

export function assertGraphqlResponse<T extends { __typename: string } | AbstractGraphQLError>({
    data,
    expectedTypenames,
    graphqlError,
    onTypeMismatch = 'LOG_ERROR',
    logErrorPrefix = '[assertGraphqlResponse]',
    logExtraData,
    urqlOptions,
    operation,
}: AssertGraphqlResponseProps<T>): Exclude<T, AbstractGraphQLError> | null {
    if (data && (expectedTypenames as string[]).includes(data.__typename)) {
        return data as Exclude<T, AbstractGraphQLError>;
    }

    if (onTypeMismatch === 'DO_NOTHING') {
        return null;
    }

    let operationPrefix = '';
    if (operation) {
        const operationDefinition = operation.query.definitions[0] as ExecutableDefinitionNode | undefined;
        const operationLabel = operationDefinition?.name?.value;
        operationPrefix = operationLabel ? `[${operationLabel}]` : '';
    }

    let logPrefix = `${operationPrefix}${logErrorPrefix}`;
    if (onTypeMismatch === 'THROW_EXCEPTION') {
        logPrefix = `[FATAL]${logPrefix}`;
    }
    const currentTypename = data?.__typename ?? 'undefined';
    let logMessage = `${logPrefix} Expected typenames: ${expectedTypenames}, got: ${currentTypename}`;

    // Network error always results in type mismatch as the data is undefined
    let isNetworkErrorIgnored = false;
    if (graphqlError?.networkError) {
        // Depends on the context, network error sometimes is the string, e.g. 'Request timeout'
        const message = `${graphqlError.networkError.message || graphqlError.networkError}`;
        logMessage = `${logPrefix} Network error - ${message}`;

        if (NETWORK_ERRORS_TO_IGNORE.has(message.toLowerCase())) {
            logMessage = `${logPrefix} Network error, ignored`;
            isNetworkErrorIgnored = true;
        }
    }

    // Use warn level to keep error context when we don't report it to Sentry
    const logFunction = onTypeMismatch === 'LOG_ERROR' && !isNetworkErrorIgnored ? logError : logWarn;
    const fetchOptions = getLogFetchOptions(urqlOptions?.fetchOptions);
    logFunction(logMessage, {
        ...logExtraData,
        graphqlError,
        fetchOptions,
    });

    if (onTypeMismatch === 'THROW_EXCEPTION') {
        throw new Error(logMessage);
    }

    return null;
}
