// Reason of disabling: When the page loads, the refresh button changes to cross button, now if some api request
// is in progress during this page loading time and the user click this cross button, then iOS chrome/safari throws
// this error.
// Source: https://forum.sentry.io/t/typeerror-failed-to-fetch-reported-over-and-overe/8447/2
export const NETWORK_ERRORS_TO_IGNORE = new Set([
    // Note: Please add it in lowercase!
    'failed to fetch', // chromium type error
    'networkerror when attempting to fetch resource.', // mozilla type error
    'load failed', // safari type error
    'cancelled',
]);
