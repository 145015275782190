import type { FC, MutableRefObject } from 'react';
import { memo } from 'react';

import type { DefaultTrackingData } from '../types';

/**
 * This is a component on purpose, to create a page view event before any page components useEffect is executed with any track event.
 *
 * The page view event has to always be the first one when a new page loads.
 */
const BaseUpdateDefaultTrackingData: FC<{
    defaultDataRef: MutableRefObject<DefaultTrackingData>;
    defaultTrackingData: DefaultTrackingData;
}> = ({ defaultTrackingData, defaultDataRef }) => {
    // Execute the content of this component on the client side just after it is mounted and it is mounted once if props aren't changed
    if (typeof window === 'undefined') {
        return null;
    }

    // On page change - reset the default tracking data, e.g. updated by some nested component
    defaultDataRef.current = defaultTrackingData;

    return null;
};

// Do not remove memo, otherwise the same tracking page view event will be sent multiple times!
export const UpdateDefaultTrackingData = memo(BaseUpdateDefaultTrackingData, (prevProps, nextProps) => {
    return prevProps.defaultTrackingData === nextProps.defaultTrackingData;
});
