import type { LaquesisPageProps } from '@lib/experiments/types/laquesisPageProps';
import type { LazyUserContextProps } from '@lib/pages/contexts/LazyUserContext/types';
import type { FC, MutableRefObject } from 'react';

import { addEventToDataLayer } from '../helpers/addEventToDataLayer';
import { trackExperiment } from '../helpers/trackExperiment';
import type { DefaultTrackingData } from '../types';

/**
 * This is a component on purpose, to create a page view event before any page components useEffect is executed with any track event.
 *
 * The page view event has to always be the first one when a new page loads.
 */
export const TrackPageView: FC<
    {
        defaultDataRef: MutableRefObject<DefaultTrackingData>;
        // It is used here to trigger the component re-render when the page is re-hydrated with the same `pageViewEventName`, e.g. Search page - but different page number
        pagePropsObjectRef: Record<string, unknown> | undefined;
        pageViewEventName: string | null;
        userContextRef: MutableRefObject<Readonly<LazyUserContextProps>>;
    } & Pick<LaquesisPageProps, 'laquesisResult'>
> = ({ defaultDataRef, laquesisResult, pagePropsObjectRef, pageViewEventName, userContextRef }) => {
    // Execute the content of this component on the client side just after it is mounted and it is executed once if props aren't changed
    // Do not remove window's props check, otherwise the same tracking page view event will be sent multiple times!
    // It is customized version of the React.memo when component may be unmounted on App re-render.
    if (
        typeof window !== 'undefined' &&
        (window.rePageViewEventName !== pageViewEventName || window.rePageViewRef !== pagePropsObjectRef)
    ) {
        window.rePageViewEventName = pageViewEventName;
        window.rePageViewRef = pagePropsObjectRef;

        if (pageViewEventName) {
            // send a PV event
            addEventToDataLayer(defaultDataRef, userContextRef, {
                trackPage: pageViewEventName,
                action_type: pageViewEventName,
                event_type: 'pv', // pv = page visit
            });
        }

        // send server experiments result - only when non-empty
        if (laquesisResult) {
            trackExperiment({ newResult: laquesisResult });
        }
    }

    return null;
};
