/* eslint-disable unicorn/filename-case -- This file has to be fully refactored to TS - rename it then */
import { GRAPHQL_QUERY_URL } from '@domains/shared/consts/graphqlQueryUrl';
import { USER_SESSION_ID_HEADER_NAME } from '@domains/shared/consts/userSessionIdHeader';
import { withUrqlClient } from 'next-urql';

export const withGraphQLClient =
    ({ component, url = GRAPHQL_QUERY_URL }) =>
    (props) =>
        withUrqlClient(() => {
            const unconfirmedUserHeaders = props?.unconfirmedUserAuthToken
                ? { authorization: `Bearer ${props.unconfirmedUserAuthToken}` }
                : undefined;

            return {
                url,
                fetchOptions: {
                    headers: {
                        // Note: userSessionId is always available when withBaseSsrHandler decorator is used
                        [USER_SESSION_ID_HEADER_NAME]: props?.userSessionId || 'missing-session-id',
                        // Note: tablet is detected as mobile
                        ...props?.desktopHeader,
                        ...unconfirmedUserHeaders,
                    },
                },
            };
        })(component)(props);
/* eslint-enable unicorn/filename-case */
