import { USER_DATA_QUERY_CONTEXT } from '@domains/shared/consts/userDataQueryContext';
import { SAVED_ADS_TYPE_NAMES } from '@domains/shared/contexts/SavedAdsContext/savedAdsTypeName';
import { SAVED_SEARCHES_TYPE_NAMES } from '@domains/shared/contexts/SavedSearchesContext/savedSearchesTypeName';

export const USER_INFO_QUERY_CONTEXT = {
    additionalTypenames: [
        ...USER_DATA_QUERY_CONTEXT.additionalTypenames,
        ...SAVED_SEARCHES_TYPE_NAMES,
        ...SAVED_ADS_TYPE_NAMES,
    ],
};
