import type { FC, JSX, PropsWithChildren, SyntheticEvent } from 'react';

import { CloseButton, CloseIcon, Label } from './Toast.theme';

// useClickOutside handles mousedown and touchstart events
// Stop propagating them to the document, otherwise, the Toasts can't be used in modals
const fixClickOutside = (event: SyntheticEvent): void => event.nativeEvent.stopImmediatePropagation();

interface Props {
    Wrapper: FC<PropsWithChildren>;
    Icon: FC;
    dataCy: string;
    label: string | JSX.Element;
    shouldHideCloseButton: boolean;
    onClick: () => void;
}

export const ToastBody = ({ Wrapper, Icon, dataCy, label, shouldHideCloseButton, onClick }: Props): JSX.Element => (
    <Wrapper data-cy={dataCy}>
        <Icon />
        <Label>{label}</Label>
        {shouldHideCloseButton ? null : (
            <CloseButton
                data-cy={`${dataCy}.close-button`}
                data-testid="close-button"
                type="button"
                onMouseDown={fixClickOutside}
                onTouchStart={fixClickOutside}
                onClick={onClick}
            >
                <CloseIcon />
            </CloseButton>
        )}
    </Wrapper>
);
