import { SITE_CONFIG } from '@config/siteConfig';
import { checkIsDesktop } from '@domains/shared/helpers/checkIsDesktop';
import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { logError, logInfo } from '@domains/shared/helpers/logger';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useLazyUser } from '@lib/pages/contexts/LazyUserContext/useLazyUser';
import type { SurveyLabels } from '@lib/tracking/types';
import { memo, useEffect, useState } from 'react';

import { checkIsExperimentEvent } from '../helpers/checkIsExperimentEvent';
import { getTrackingUserData } from '../helpers/getTrackingUserData';
import { sendExperimentTrackingToLaquesis } from '../helpers/sendExperimentTrackingToLaquesis';

const { ninjaEnvironment } = getPublicEnvConfig();
const { ninjaScriptUrl, siteUrl } = SITE_CONFIG.tracking;

const loadNinjaScript = (): void => {
    // Delay loading until the main thread is free
    setTimeout(() => {
        if (document.querySelector('#ninja-script-tag')) {
            // Make sure that script is not loaded twice

            return;
        }
        const script = document.createElement('script');
        script.id = 'ninja-script-tag';
        script.defer = true;
        script.addEventListener('error', (error): void => {
            logError('[TrackingContext] Ninja script loading error', { error });
        });
        script.addEventListener('load', (): void => {
            logInfo('[TrackingContext] Ninja script loaded');
            window.reNinjaLoadCallback?.();
        });
        script.src = ninjaScriptUrl;
        document.querySelectorAll('body')[0].append(script);
    }, 0);
};

/**
 * This is a component on purpose (instead of using a custom hook).
 * Children useEffect is mounted as first (before parents' ones).
 *
 * Cases:
 * 1. A user enters the page
 * - ninja load / user data fetching -> send data to temp window storage
 * - both deps loaded -> send data to `window.dataLayer`
 * Note: if a user leaves the page before ninja and user data is loaded - tracking data is lost
 * 2. A user moves between pages (SPA session)
 * - ninja load / user data fetching -> this data already exist -> send data to `window.dataLayer`
 */
export const MountWindowDependenciesBase = (): null => {
    const [t] = useTranslations();
    const { isAwaitingUserData, user } = useLazyUser();
    // As TrackingContextProvider is mounted per page - this state is reset on every page/route mount
    const [isNinjaReady, setIsNinjaReady] = useState(false);

    if (typeof window !== 'undefined' && window.reIsNinjaReady === undefined) {
        // Set the default storage
        window.reTrackingContextStorage = window.reTrackingContextStorage || [];
        window.reTrackingStorageToUse = window.reTrackingStorageToUse || 'temp';
        window.dataLayer = window.dataLayer || [];
        window.reIsNinjaReady = window.reIsNinjaReady || false;
        // Strict Laquesis
        // A custom implementation of the cookie watcher to handle result sent to client by props instead of cookie
        // https://docs.data.olx.org/services/laquesis/technical-guide/sdks/cookie-watcher.html
        window.laquesisResults = window.laquesisResults || [];
        window.rePageExperimentsImpressions = window.rePageExperimentsImpressions || new Set();
        window.reLaquesisFunctionsCallbackQue = window.reLaquesisFunctionsCallbackQue || [];
        window.laquesisFunctionsCallback = (): void => {
            logInfo(
                '[TrackingContext] laquesisFunctionsCallback called',
                { queLength: window.reLaquesisFunctionsCallbackQue.length },
                { hideOnEnvs: ['production.browser', 'test'] },
            );
            for (const callback of window.reLaquesisFunctionsCallbackQue) callback();
            window.reLaquesisFunctionsCallbackQue.length = 0;
        };
    }

    // Initialize the shared storage in window scope
    useEffect(() => {
        if (window.reIsNinjaReady) {
            setIsNinjaReady(true);

            return;
        }

        let isMounted = true;

        // Add a listener when LQ is loaded
        window.reLaquesisFunctionsCallbackQue.push((): void => {
            logInfo('[TrackingContext] LQ is ready to consume impressions', undefined, {
                hideOnEnvs: ['production.browser', 'test'],
            });
            if (window.reIsNinjaReady || !isMounted) {
                // if Ninja is loaded - skip, if the component was unmounted skip as well
                return;
            }
            // Laquesis is loaded by Ninja, so when it is ready, mark that Ninja is ready as well
            window.reIsNinjaReady = true;
            setIsNinjaReady(true);
        });

        let ninjaLoadCallbackTimeoutId: ReturnType<typeof window.setTimeout> | undefined;

        // Add a listener when Ninja is loaded
        window.reNinjaLoadCallback = (): void => {
            // Wait for LQ, if not ready in 1s, send all Ninja events immediately
            ninjaLoadCallbackTimeoutId = setTimeout(() => {
                ninjaLoadCallbackTimeoutId = undefined; // reset the timeout id

                if (window.reIsNinjaReady) {
                    // skip when LQ was loaded or the component was unmounted
                    return;
                }
                logInfo(
                    '[TrackingContext] LQ load timeout - send Ninja events',
                    { isMounted },
                    { hideOnEnvs: ['production.browser', 'test'] },
                );
                window.reIsNinjaReady = true;
                if (!isMounted) {
                    return;
                }
                window.reLaquesisFunctionsCallbackQue.length = 0; // Clear the LQ callback que (as it is not needed anymore)

                setIsNinjaReady(true);
            }, 1000);
        };

        return (): void => {
            // Cleanup when component is unmounted
            isMounted = false;
            window.reNinjaLoadCallback = undefined;
            window.reLaquesisFunctionsCallbackQue.length = 0;
            if (ninjaLoadCallbackTimeoutId) {
                // Ninja was loaded - timeout was set, however, the component was unmounted until it was executed.
                // Mark for future calls mark Ninja as ready
                clearTimeout(ninjaLoadCallbackTimeoutId);
                window.reIsNinjaReady = true;
            }
        };
    }, []); // Always make sure that in this useEffect there is an empty dep list (it is executed once only)

    // Set Ninja & Laquesis configuration data
    useEffect(() => {
        if (window.configTracking || isAwaitingUserData) {
            // It was already defined in the previous SPA page || we are awaiting user data
            return;
        }

        const surveyLabels: SurveyLabels = {
            decline: t('frontend.laquesisSurvey.label.decline'),
            accept: t('frontend.laquesisSurvey.label.accept'),
            next: t('frontend.laquesisSurvey.label.next'),
            finish: t('frontend.laquesisSurvey.label.finish'),
            radioGroupHint: t('frontend.laquesisSurvey.label.radioGroupHint'),
            checkBoxGroupHint: t('frontend.laquesisSurvey.label.checkBoxGroupHint'),
            multiLineInputHint: t('frontend.laquesisSurvey.label.multiLineInputHint'),
            singleInputHint: t('frontend.laquesisSurvey.label.singleInputHint'),
        };

        const developmentLaquesisPlugin = ninjaEnvironment === 'production' ? {} : { plugins: ['LQ'] };

        // https://ninja.onap.io/demo.html
        // https://docs.data.olx.org/components/sdk/ninja/implementation-web.html#the-site-configuration
        window.configTracking = {
            environment: ninjaEnvironment,
            disableDefaultTrackPage: true, // Disable the default first page track event. Useful for PWAs with async ninja initialisation.
            disablePropertyPropagation: true, // Disable previous events' properties propagation. Use only if you are sure your tracking is in good shape.
            siteUrl,
            platform: checkIsDesktop() ? 'd' : 'm',
            surveys: surveyLabels,
            ...developmentLaquesisPlugin,
            /* Debug - uncomment below lines to debug */
            /*
            laquesisQa: {
                enable: true, // Enable the QA mode, by default: false
                icon: true, // Show or not show the icon, by default: true (if it is false, you can call the menu with window.showLaquesisQaMenu())
                style: true, // Show the menu with laquesis styles, by default: true (if false you can use your
                expires: 5, // Minutes after the QA cookie expires, by default 2 hours
            },
             */
            // Below information are required by Laquesis
            // Note: it is set only when user data is available, if not, it is later on updated with laquesisSetUserId
            // @link: https://docs.data.olx.org/components/sdk/laquesis/integrate-javascript.html#apply-user-id
            ...(user?.id ? { userId: user?.id } : {}),
        };

        // Note: isMounted is used to avoid useState call when component has been already unmounted

        logInfo('[TrackingContext] LQ callback added to que - awaiting loading', undefined, {
            hideOnEnvs: ['production.browser', 'test'],
        });

        loadNinjaScript();
    }, [isAwaitingUserData, t, user]);

    // Proceeding the awaiting queue (read from window)
    useEffect(() => {
        if (!isNinjaReady || isAwaitingUserData) {
            window.reTrackingStorageToUse = 'temp';

            return;
        }

        // Update the information about the user only when Laquesis is available, otherwise data from `window.configTracking` should be used
        // @link https://docs.data.olx.org/components/sdk/laquesis/integrate-javascript.html#apply-user-id
        if (user?.id) {
            if (window.laquesisSetUserId !== undefined) {
                logInfo('[TrackingContext] LQ set user', undefined, { hideOnEnvs: ['production.browser', 'test'] });
                window.laquesisSetUserId(user.id);
            }
        } else {
            if (window.laquesisDropUserId !== undefined) {
                logInfo('[TrackingContext] LQ drop user', undefined, { hideOnEnvs: ['production.browser', 'test'] });
                window.laquesisDropUserId();
            }
        }

        // Proceed the awaiting data, add missing user context
        for (const item of window.reTrackingContextStorage) {
            if (checkIsExperimentEvent(item)) {
                sendExperimentTrackingToLaquesis(item, false);

                continue;
            }

            const { shouldAddUserData, ...event } = item;

            window.dataLayer.push({
                ...(shouldAddUserData ? getTrackingUserData(user) : null),
                ...event,
            });
        }
        window.reTrackingContextStorage.length = 0;
        window.reTrackingStorageToUse = 'dataLayer';
    }, [isNinjaReady, isAwaitingUserData, user]);

    return null;
};

export const MountWindowDependencies = memo(MountWindowDependenciesBase);
