import { useContext } from 'react';

import { LazyUserContext } from './LazyUserContext';
import type { LazyUserContextProps } from './types';

/**
 * This hook data is available on all pages. The user data is lazy fetched using useQuery,
 *  which is executed on the client side only and allows creating a user-agnostic pages.
 *
 *  Please note: urql caches the response, so the response is almost immediate available in the SPA
 *  session, however, during the first load it can be unavailable in the first few milliseconds.
 *  That's why it is especially important to prepare your functionality (especially UI) and check
 *  before executing any logic if the user data is available - use `isAwaitingUserData` - flag,
 *  e.g. such button responsible for that can be disabled or may show the spinner or
 *  fallback placeholder.
 */
export const useLazyUser = (): Readonly<LazyUserContextProps> => {
    const data = useContext(LazyUserContext);

    if (!data) {
        throw new TypeError('LazyUserContext is not available!');
    }

    return data;
};
