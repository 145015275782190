import type { ExperimentEvent } from '@lib/tracking/types';

import { sendExperimentTrackingToLaquesis } from './sendExperimentTrackingToLaquesis';

/**
 * Function responsible for tracking experiments. Internal use only to communicate between
 * TrackingContext & experiments context.
 */
export const trackExperiment = (value: ExperimentEvent): void => {
    if (typeof window === 'undefined') {
        // We do not send tracking during SSR
        return;
    }
    if (window.reTrackingStorageToUse === 'dataLayer') {
        // Laquesis is ready to send tracking directly
        sendExperimentTrackingToLaquesis(value);

        return;
    }
    // Laquesis isn't ready to send events directly, however, global temp storage is ready to use
    if ('experimentName' in value) {
        const experimentDebouncedName = `${value.experimentName}@${value.variant}`;
        if (window.rePageExperimentsImpressions.has(experimentDebouncedName)) {
            // it was already reported, skip adding
            return;
        }
        window.rePageExperimentsImpressions.add(experimentDebouncedName);
    }
    window.reTrackingContextStorage.push(value);
};
