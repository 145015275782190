import type { Translations } from '@lib/i18n/types/translations';
import type { FC, ReactElement, ReactNode } from 'react';
import { createContext } from 'react';

export const I18NContext = createContext({});

interface I18NContextProviderProps {
    children: ReactNode;
    translations: Translations;
}

export const I18NContextProvider: FC<I18NContextProviderProps> = ({ children, translations = {} }): ReactElement => {
    return <I18NContext.Provider value={translations}>{children}</I18NContext.Provider>;
};
