import { logError } from '@domains/shared/helpers/logger';
import type { LaquesisPageProps } from '@lib/experiments/types/laquesisPageProps';
import { useLazyUser } from '@lib/pages/contexts/LazyUserContext/useLazyUser';
import { UpdateDefaultTrackingData } from '@lib/tracking/components/UpdateDefaultTrackingData';
import type { FC, ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { MountWindowDependencies } from './components/MountWindowDependencies';
import { TrackPageView } from './components/TrackPageView';
import { addEventToDataLayer } from './helpers/addEventToDataLayer';
import { trackExperiment } from './helpers/trackExperiment';
import { PageTrackingContext } from './PageTrackingContext';
import type { DefaultTrackingData, ExperimentEvent, TrackerConfig, TrackingEvent } from './types';
import { useTracking } from './useTracking';

// Ninja requires some additional properties in `window` object.
// Additionally extend window storage to let store events between SPA sessions
// - page loading until Ninja Script is loaded.
declare global {
    interface Window {
        dataLayer: TrackingEvent[];
        configTracking: TrackerConfig;

        // Custom storage until a user data is loaded
        reNinjaLoadCallback?: () => void;
        reTrackingContextStorage: ((TrackingEvent & { shouldAddUserData?: true }) | ExperimentEvent)[];
        reIsNinjaReady: boolean;
        reTrackingStorageToUse: 'temp' | 'dataLayer';
        plugins?: ['LQ']; // Strict development purpose

        // Page tracking ref data comparison
        rePageViewRef?: Record<string, unknown>;
        rePageViewEventName?: string | null;

        // Strict Laquesis
        laquesisResults: { newResult: string; processed?: boolean }[];
        getLaquesisVariant?: (experiment: string) => string | null;
        rePageExperimentsImpressions: Set<string>;
        reLaquesisFunctionsCallbackQue: (() => void)[];
        /**
         * The SDK will call it when the library is loaded and ready to use
         */
        laquesisFunctionsCallback?: () => void;
        laquesisSetUserId?: (userId: number | string) => void;
        laquesisDropUserId?: () => void;
    }
}

export interface TrackingContextProviderProps extends Pick<LaquesisPageProps, 'laquesisResult'> {
    children: ReactNode;
    defaultTrackingData?: DefaultTrackingData;
    pageViewEventName: string | null;
    /** Provide reference to page props if the page may be re-hydrated with the same track page name multiple times */
    pagePropsObjectRef: Record<string, unknown> | undefined;
}

const DEFAULT_ADDITIONAL_TRACKING_DATA: DefaultTrackingData = null;

/**
 * This context is responsible for initializing and loading Ninja Tracker script.
 * Events are added to the temporary `window.reTrackingContextStorage`
 * data storage (events queue) until a user data is available (we know whether
 * they are logged in) and ninja script is loaded.
 *
 * There is a corner case when events are added to the queue in one page (route)
 * but the script hasn't been proceeded yet, then events are sent on a different
 * page, however, still with the correct tracking data.
 *
 * Please note: events are lost if a user exits a page and all dependencies haven't
 * been loaded, e.g. ninja/laquesis scripts and information about a user.
 *
 * How do we know when Ninja is ready and user data was loaded?
 * 1. We add onLoad event to Ninja script and later on timeout to let LQ initialize
 * 2. LazyUserContext is responsible for user data fetching.
 *
 * Related docs:
 * - https://docs.data.olx.org/components/sdk/ninja/implementation-web.html#define-the-ninja-library
 * - https://docs.data.olx.org/components/sdk/laquesis/how-to-integrate.html
 */
export const TrackingContextProvider: FC<TrackingContextProviderProps> = ({
    children,
    defaultTrackingData = DEFAULT_ADDITIONAL_TRACKING_DATA,
    laquesisResult,
    pagePropsObjectRef,
    pageViewEventName,
}) => {
    // Note: TrackingContextProvider is created per page, so it is reset on every route (page) change
    const defaultDataRef = useRef(defaultTrackingData);
    const userContext = useLazyUser();
    const userContextRef = useRef(userContext);

    // Update references - we keep a user data as ref to avoid callbacks re-creating because of user data
    userContextRef.current = userContext;

    const { hasBeenLoaded } = useTracking();
    useEffect(() => {
        if (hasBeenLoaded) {
            logError('[TrackingContext] can be defined once in the page only');
        }
    }, [hasBeenLoaded]);

    /**
     * Updates the default additional tracking data.
     *
     * Please use this function with cautious as it may create a lot of side effects depends on where it is used, e.g.
     * if in the Page or Nested component. It is much better to set a global default additional data.
     */
    const updateDefaultAdditionalData = useCallback((data: DefaultTrackingData, shouldAppend = false) => {
        defaultDataRef.current = {
            ...(shouldAppend ? defaultDataRef.current : null),
            ...data,
        };
    }, []);

    /**
     * Tracking methods below only.
     * They are inside context because they are using internal refs and context properties.
     */

    /**
     * Function responsible for explicit tracking of single events.
     * Output is number since array.push returns length of new array.
     */
    const trackEvent = useCallback(
        (eventName: string, extraData: DefaultTrackingData = null, eventType: string | null = 'click'): void => {
            addEventToDataLayer(defaultDataRef, userContextRef, {
                trackEvent: [eventName],
                action_type: eventName,
                ...(eventType ? { event_type: eventType } : null),
                ...extraData,
            });
        },
        [],
    );

    const value = useMemo(
        () => ({
            hasBeenLoaded: true,
            trackEvent,
            trackExperiment,
            updateDefaultAdditionalData,
        }),
        [trackEvent, updateDefaultAdditionalData],
    );

    return (
        <PageTrackingContext.Provider value={value}>
            <MountWindowDependencies />
            {/* Update the default tracking data. Use component-page render flow to keep the consistency */}
            <UpdateDefaultTrackingData defaultTrackingData={defaultTrackingData} defaultDataRef={defaultDataRef} />
            <TrackPageView
                defaultDataRef={defaultDataRef}
                laquesisResult={laquesisResult}
                pageViewEventName={pageViewEventName}
                pagePropsObjectRef={pagePropsObjectRef}
                userContextRef={userContextRef}
            />
            {children}
        </PageTrackingContext.Provider>
    );
};
